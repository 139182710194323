import { animated } from 'react-spring';
import styled from 'styled-components';

export const StyledContact = styled.section`
	align-items: flex-end;
	display: flex;
	height: 100vh;
	width: 100%;
`;

export const ContactContainer = styled.div`
	display: flex;
	height: 60%;
	padding-bottom: 5vh;
	width: 100%;
	@media screen and (max-width: 800px) {
		height: 80%;
		flex-direction: column;
	}
`;

export const ContactContent = styled(animated.div)`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	flex: 1 0 50%;
	height: 100%;
	justify-content: space-between;
	padding: 0 5vw;
	@media screen and (max-width: 800px) {
		justify-content: space-evenly;
	}
`;

export const ContactTitle = styled.h1`
	font-size: 48px;
	font-weight: 200;
	position: relative;
	margin: 0;
	&:after {
		background: ${(props) => props.theme.colorAccent};
		border-bottom: 3px solid #ff637d;
		border-radius: 1px;
		content: '';
		height: 60%;
		left: -10px;
		mix-blend-mode: lighten;
		opacity: .8;
		position: absolute;
		top: 35%;
		transform: skew(10deg, -2deg);
		width: 100%;
	}

	@media screen and (max-width: 450px) {
		font-size: 38px;
		&:after {
			left: 0;
		}
	}
`;

export const ContactEmail = styled.a`
	color: white;
	line-height: 2;
`;
