import React, { useState, useRef, ChangeEvent } from 'react';
import { animated } from 'react-spring';

import './contact-form.scss';
import Button from '../Button/button';

export default function ContactForm({ innerStyle }) {
	const form = useRef(null);

	const [status, setStatus] = useState(null);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');

	const encode = (data) => {
		const formData = new FormData();
		Object.keys(data).forEach((k) => {
			formData.append(k, data[k]);
		});
		return formData;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const data = { 'form-name': 'contact', name, email, message };

		fetch('/', {
			method: 'POST',
			body: encode(data),
		})
			.then(() => {
				setStatus(`Groovy! We'll get back to you soon.`);
				setName('');
				setEmail('');
				setMessage('');
			})
			.catch((error) => setStatus('Form Submission Failed!'));
	};

	const handleChange = (e: ChangeEvent) => {
		const { name, value } = e.target as HTMLInputElement;
		switch (name) {
			case 'name':
				setName(value);
				break;
			case 'email':
				setEmail(value);
				break;
			case 'message':
				setMessage(value);
				break;
			default:
				return;
		}
	};

	return (
		<animated.div className="contact-form" style={{ ...innerStyle }}>
			<form
				name="contact"
				data-netlify="true"
				data-netlify-honeypot="bot-field"
				method="post"
				ref={form}
				onSubmit={handleSubmit}
			>
				<input type="hidden" name="form-name" value="contact" />
				<input
					onChange={handleChange}
					className="contact-form__input"
					placeholder="Name"
					type="text"
					name="name"
					value={name}
				/>
				<input
					className="contact-form__input"
					onChange={handleChange}
					placeholder="Email"
					type="email"
					name="email"
					value={email}
				/>
				<textarea
					className="contact-form__input contact-form__textarea"
					onChange={handleChange}
					placeholder="Message"
					name="message"
					value={message}
				/>
				<div>
					<Button disabled={!name.length || !email.length || !message.length}>
						Send
					</Button>
					{status && <span className="contact-form__status">{status}</span>}
				</div>
			</form>
		</animated.div>
	);
}
