import React, { useRef } from 'react';

import {
	StyledContact,
	ContactContent,
	ContactTitle,
	ContactEmail,
	ContactContainer,
} from './styled';
import ContactForm from '../ContactForm/contact-form';
import { useSpring, useChain, useTrail, animated } from 'react-spring';

export default function Contact() {
	const content = [
		<ContactTitle>Let's get wonky.</ContactTitle>,
		<ContactEmail href="mailto:hello@wonkylines.io">
			hello@wonkylines.io
		</ContactEmail>,
	];

	const contentRef = useRef();
	const contentTrail = useTrail(content.length, {
		opacity: 1,
		x: 0,
		from: {
			opacity: 0,
			x: -10,
		},
		ref: contentRef,
	});

	const formRef = useRef();
	const formSpring = useSpring({
		opacity: 1,
		transform: `translateY(0%)`,
		from: {
			opacity: 0,
			transform: `translateY(10%)`,
		},
		ref: formRef,
	});

	useChain([contentRef, formRef], [0.1, 0.4]);

	return (
		<StyledContact>
			<ContactContainer>
				<ContactContent>
					{contentTrail.map(({ opacity, x }, index) => (
						<animated.div
							key={index}
							style={{
								backfaceVisibility: 'hidden',
								opacity: opacity.interpolate((opacity) => opacity),
								transform: x.interpolate((x) => `translate3d(${x}%,0,0)`),
							}}
						>
							{content[index]}
						</animated.div>
					))}
				</ContactContent>
				<ContactForm innerStyle={formSpring} />
			</ContactContainer>
		</StyledContact>
	);
}
