import React, { Fragment } from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/SEO/SEO';
import Contact from '../components/Contact/Contact';

export default ({ data }) => {
	const { site } = data;
	const { siteMetadata } = site;
	const description = "Let's get wonky.";

	return (
		<Fragment>
			<SEO>
				<title>{siteMetadata.title} - Contact</title>
				<meta name="description" content={description} />
				<meta property="og:title" content={siteMetadata.title} />
				<meta property="og:description" content={description} />
			</SEO>

			<Contact />
		</Fragment>
	);
};
export const pageQuery = graphql`
	query ContactQuery {
		site {
			...SiteMetadata
		}
	}
`;
